import React from 'react';
import './contact.styles.scss'

const Contact = () => {
    return (
        <div className="container">
            <div className="contact-container">
                <div className="contact-sup">
                <h3>
                    Want to get to know me better?
                    <br/>
                    Drop me a mail!
                </h3>
                <p className="contact">    
                    <br/>
                    
                    nicolasnollomont@gmail.com
                    <br/>
               
                    <br/>
                    Rue Des Sables 12a
                    <br/>
                    1000 Brussels
                    <br/>
                    Belgium
                    <br/>
                    <br/>
                    + 32 478 078 523
                </p>
                </div>
        </div>
        </div>
    )
}

export default Contact;